<template>
  <!--<a-table :columns="columns" :data-source="previews" bordered></a-table>-->
  <div class="preview">
    <img class="img" :src="getImagePath()" alt="" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Preview",
  computed: {
    ...mapGetters({
      columns: "csPrices/previewColumns",
      currentBrand: "csPrices/currentBrand",
    }),
  },
  methods: {
    getImagePath() {
      return this.currentBrand
        ? require(`../../../assets/pricescs/table${this.currentBrand}.jpg`)
        : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.preview {
  display: flex;
  justify-content: center;

  .img {
    max-width: 100%;
  }
}
</style>
