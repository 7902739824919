<template>
  <div class="cs-prices-page">
    <h3>Последние версии файла наличия ЦС:</h3>
    <div class="directories-cs-table">
      <PricesCsList />
      <Download />
    </div>
  </div>
</template>

<script>
import Download from "@/components/prices-cs/download/Download"
import PricesCsList from "@/components/prices-cs/List"

export default {
  name: "PricesCS",
  components: {
    PricesCsList,
    Download,
  },
}
</script>

<style lang="scss" scoped></style>
